<template>
  <!-- 结算管理 - 开票单位管理 -->
  <div class="pageContol listWrap templateList faceStudentstatistics">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">结算管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">开票单位管理</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 10px">
            <div title="单位名称" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 6rem">单位名称:</span>
              <el-select
                size="small"
                v-model="retrievalData.closeCompany"
                remote
                :remote-method="getCompanyList"
                filterable
                clearable
                placeholder="输入关键字"
                @change="bindChange"
              >
                <el-option
                  v-for="item in CompanyList"
                  :key="item.closeCompanyId"
                  :label="item.closeCompany"
                  :value="item.closeCompanyId"
                ></el-option>
              </el-select>
            </div>
            <div class="df">
              <el-button
                type="primary"
                size="small"
                class="bgc-bv"
                round
                @click="getData()"
                >查询</el-button
              >
              <el-button
                type="primary"
                size="small"
                class="bgc-bv"
                round
                @click="addEdit"
                >新增</el-button
              >
              <el-button
                type="primary"
                size="small"
                class="bgc-bv"
                style="margin-right: 22px;"
                round
                @click="IssuingOfficeUpload()"
                >开票单位上传</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
              ><el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                width="80px"
              /><el-table-column
                label="单位名称"
                align="left"
                show-overflow-tooltip
                prop="closeCompany"
                minWidth="160"
              />
              <el-table-column
                label="税号"
                align="left"
                show-overflow-tooltip
                prop="taxNo"
                minWidth="120"
              />
              <el-table-column
                label="地址电话"
                align="left"
                show-overflow-tooltip
                prop="addressMobile"
                minWidth="100"
              />
              <el-table-column
                label="开户行及账号"
                align="left"
                show-overflow-tooltip
                prop="accountNumber"
                minWidth="140"
              />
              <el-table-column
                label="邮箱"
                align="left"
                show-overflow-tooltip
                prop="email"
                minWidth="100"
              />
              <el-table-column
                label="创建日期"
                align="center"
                show-overflow-tooltip
                width="120"
                ><template slot-scope="scope">{{
                  scope.row.createTime | momentDate
                }}</template>
              </el-table-column>
              <el-table-column
                label="操作"
                align="center"
                width="100px"
                fixed="right"
              >
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 0 5px"
                    @click="addEdit(scope.row)"
                    >编辑</el-button
                  >
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <!-- 新增/编辑 - 弹框 -->
    <el-dialog
      :title="rejectzDta.closeCompanyId ? '编辑' : '新增'"
      :visible.sync="rejectzledialog"
      width="40%"
      top="10%"
      :before-close="addNo"
      ><div class="ovy-a">
        <el-form
          :model="rejectzDta"
          :rules="rules"
          ref="rejectzDta"
          label-width="120px"
          class="demo-ruleForm basicData"
          ><el-form-item label="收票单位" prop="closeCompany">
            <el-input
              clearable
              placeholder="请输入收票单位"
              size="small"
              v-model="rejectzDta.closeCompany"
            ></el-input>
          </el-form-item>
          <el-form-item label="税号" prop="taxNo">
            <el-input
              clearable
              placeholder="请输入税号"
              size="small"
              v-model="rejectzDta.taxNo"
            ></el-input>
          </el-form-item>
          <el-form-item label="地址、电话" prop="addressMobile">
            <el-input
              clearable
              placeholder="请输入地址、电话"
              size="small"
              v-model="rejectzDta.addressMobile"
            ></el-input>
          </el-form-item>
          <el-form-item label="开户行及账号" prop="accountNumber">
            <el-input
              clearable
              placeholder="请输入开户行及账号"
              size="small"
              v-model="rejectzDta.accountNumber"
            ></el-input>
          </el-form-item>
          <el-form-item label="邮箱" prop="email">
            <el-input
              clearable
              placeholder="请输入邮箱"
              size="small"
              v-model="rejectzDta.email"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer flexcc">
        <el-button @click="addNo">取 消</el-button>
        <el-button @click="addOk" class="bgc-bv" type="primary"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!-- 批量导入 -->
    <el-dialog
      title="开票单位批量导入"
      :visible.sync="centerDialogVisible"
      width="50%"
      center
      :before-close="doCloseload"
      class="exportdialog"
    >
      <div class="flexdcc">
        <div class="export-box">
          <h1>
            1
            <span>下载并填写导入模板</span>
          </h1>
          <div class="df">
            <span
              @click="downloadExcel"
              style="margin-top:20px;margin-right:20px;color:#409eff;cursor: pointer;"
              >开票单位导入模版</span
            >
          </div>
        </div>
        <div class="export-box">
          <h1>
            2
            <span>导入编写好的Excel文档</span>
          </h1>
          <div class="flexdc">
            <div class="df" style="margin:20px 0 10px">
              <el-upload
                class="upload-demo upload-workers"
                :action="actionUrl"
                :on-error="handleError"
                :on-success="handleSuccess"
                :on-change="uploadChange"
                :show-file-list="false"
                :auto-upload="false"
              >
                <el-button class="bgc-bv" style="font-size:12px;width: 80%;"
                  >浏览...</el-button
                >
              </el-upload>
              <p v-if="fileName" style="margin-top:10px;margin-left:10px">
                当前选择文件：
                <span style="color:#f46173">{{ fileName }}</span>
              </p>
              <p v-else style="margin-top:10px;margin-left:10px">未选择文件</p>
            </div>
            <div>
              <el-button
                class="bgc-bv"
                size="mini"
                style="margin-top:10px;height: 35px;"
                @click="doExportIn"
                >开始导入</el-button
              >
            </div>
          </div>
        </div>
        <div class="export-box">
          <h1>
            3
            <span>导入结果</span>
          </h1>
          <div class="df studentNum" style="padding:1rem 0 0 0">
            <span>总数量:{{ totalNum }}个;</span>
            <span>成功:{{ successNum }}个;</span>
            <span>失败:{{ totalNum - successNum }}个;</span>
          </div>
          <div>
            <el-button
              class="bgc-bv"
              style="margin-top:15px;height: 35px;"
              size="mini"
              :disabled="totalNum - successNum == 0"
              @click="doExportError"
              >导出错误数据</el-button
            >
          </div>
          <div
            style="margin-top:15px;color:#DD1D35;"
            v-if="totalNum - successNum != 0"
          >
            有导入失败开票单位，请导出错误数据，将导入失败的开票单位调整后重新上传
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
import { mapGetters } from "vuex";
export default {
  name: "InvoicingCompanyManagement",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 检索数据集
      retrievalData: {
        // 单位名称
        closeCompany: "",
      },
      // 收票单位 - 下拉数据
      CompanyList: [],
      // 新增/编辑 - loading
      rejectzledialog: false,
      // 新增/编辑 - 数据
      rejectzDta: {
        // 收票单位
        closeCompanyId: "",
        // 收票单位
        closeCompany: "",
        // 税号
        taxNo: "",
        // 地址、电话
        addressMobile: "",
        // 开户行及账号
        accountNumber: "",
        // 邮箱
        email: "",
      },
      // 基础数据集 - 数据校验
      rules: {
        // 收票单位
        closeCompany: [
          { required: true, message: "请输入收票单位", trigger: "blur" },
        ],
        // 税号
        taxNo: [{ required: true, message: "请输入税号", trigger: "blur" }],
        // // 地址、税号
        // addressMobile: [
        //   { required: true, message: "请输入地址、税号", trigger: "blur" },
        // ],
        // // 开户行及账号
        // accountNumber: [
        //   { required: true, message: "请输入开户行及账号", trigger: "blur" },
        // ],
        // // 邮箱
        // email: [{ required: true, message: "请输入邮箱", trigger: "blur" }],
      },
      /* 开票单位导入数据 */
      centerDialogVisible: false,
       fileName: "",
      fileKey: "",
      errorNum: 0,
      totalNum: 0,
      successNum: 0,
      taskIds:[]
    };
  },
  created() {
    this.getCompanyList();
  },
   computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
      userJson: "getUser",
    }),
  },
  mounted() {},
  methods: {
    // 获取数据 - 收票单位
    getCompanyList(query) {
      this.$post("/biz/new/bill/invoice/company/pageList", {
        closeCompany: query,
      })
        .then((res) => {
          if (res.status == 0) {
            this.CompanyList = res.data.list || [];
          }
        })
        .catch(() => {
          return;
        });
    },
    // 选中 - 收票单位 - 获取赋值name
    bindChange(val) {
      let obj = {};
      obj = this.CompanyList.find((item) => {
        //这里的userList就是上面遍历的数据源
        return item.closeCompanyId === val; //筛选出匹配数据
      });
      this.retrievalData.closeCompany = obj.closeCompany;
    },
    // 初始化获取列表
    getData(pageNum = 1) {
      const params = {
        // 当前页数
        pageNum: pageNum,
        // 每页条数
        pageSize: this.pageSize,
      };
      // 开票单位
      if (this.retrievalData.closeCompany) {
        params.closeCompany = this.retrievalData.closeCompany;
      }
      this.doFetch({
        url: "/biz/new/bill/invoice/company/pageList",
        params,
        pageNum,
      });
    },
    // 添加/编辑
    addEdit(row) {
      if (row) {
        // 收票单位 - id
        this.rejectzDta.closeCompanyId = row.closeCompanyId;
        // 收票单位
        this.rejectzDta.closeCompany = row.closeCompany;
        // 税号
        this.rejectzDta.taxNo = row.taxNo;
        // 地址、电话
        this.rejectzDta.addressMobile = row.addressMobile;
        // 开户行及账号
        this.rejectzDta.accountNumber = row.accountNumber;
        // 邮箱
        this.rejectzDta.email = row.email;
      }
      this.rejectzledialog = true;
    },
    // 新增/编辑 - 取消
    addNo() {
      Object.keys(this.rejectzDta).forEach((key) => {
        this.rejectzDta[key] = "";
      });
      this.rejectzledialog = false;
       const el = this.$refs["rejectzDta"];
    el.clearValidate();
    },
    // 新增/编辑 - 确定
    addOk() {
        const el = this.$refs["rejectzDta"];
    el.clearValidate("compIdcard");
      el.validate(valid => {
        if (valid) {
          let params = {
        ...this.rejectzDta,
      };
      this.$post("/biz/new/bill/invoice/company/saveOrUpdate", params).then(
        (res) => {
          if (res.status == 0) {
            this.getData();
            Object.keys(this.rejectzDta).forEach((key) => {
              this.rejectzDta[key] = "";
            });
            this.$message({
              type: "success",
              message: this.rejectzDta.closeCompanyId ? "编辑成功" : "新增成功",
            });
            this.rejectzledialog = false;
          }
        }
      );
        }
      });
    
    },
    IssuingOfficeUpload() {
      this.centerDialogVisible = true;
    },
    downloadExcel() {
      /**
       * 下载模板
       * @param param 参数
       */
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = "/static/开票单位导入模板.xlsx";
      link.setAttribute("download", "开票单位导入模板.xlsx");
      document.body.appendChild(link);
      link.click();
    },
    // 导入备注
    uploadChange(file) {
      this.fileName = file.name;
      let size = file.size / 1024 / 1024;
      // let fileName = file.name.split(".");
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isXLSX = extension === "XLSX";
      const isxlsx = extension === "xlsx";
      const isXLS = extension === "XLS";
      const isxls = extension === "xls";
      if (!isXLSX && !isxlsx && !isXLS && !isxls) {
        this.$message.error("只能上传后缀是.xlsx或者.xls的文件");
        return;
      }
      if (size > 1) {
        this.$message.error("文件大小不能超过1M");
        return;
      }
      let formData = new FormData();
      formData.append("folder ", "USER");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);

      this.$Postformat("/sys/upload", formData)
        .then((result) => {
          // console.log(result);
          this.fileKey = result.data.fileKey;
          // this.fileName = result.data.fileKey;
        })
        .catch((err) => {
          return;
        });
    },
    doExportIn() {
      if (this.fileKey == "") {
        this.$message({
          message: "请选择文件",
          type: "warning",
        });
      } else {
        this.$confirm("是否导入此文件?", "提示", {
          confirmButtonText: "确定",
          confirmButtonClass: "confirmButtonClass",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            if (this.fileKey) {
              const parmar = {
                fileURL: this.fileKey,
              };
              this.$post("/biz/new/bill/invoice/company/importExcel", parmar, 5000)
                .then((res) => {
                  if (res.status == 0) {
                    this.totalNum = res.data.totalNum;
                    this.errorNum = res.data.errorNum;
                    this.successNum = res.data.successNum;
                    this.taskIds = res.data.taskId;
                    if (res.data.projectCount - res.data.successNum == 0) {
                      this.$message({
                        type: "success",
                        message: "导入成功",
                      });
                    }
                  }
                })
                .catch(() => {
                  return;
                });
            } else {
              this.$message({
                message: "请选择文件",
                type: "warning",
              });
            }
          })
          .catch(() => {
            return;
          });
      }
    },
    //导出错误数据
    doExportError() {
      // console.log(this.batchId);
      if (this.taskIds == "" || this.fileKey == "") {
        this.$message({
          message: "还没有导入数据，请先导入文档",
          type: "warning",
        });
      } else {
        console.log(this.taskIds);
        this.$post("/sys/download/queryDownloadState", {
          taskIds: this.taskIds,
        }).then((res) => {
          if (res.status == "0") {
            let list = [];
            list.push(res.data);
            for (let item of list) {
              if (!this.downloadItems.includes(item.taskId)) {
                this.$store.dispatch("pushDownloadItems", item[0].taskId);
              } else {
                console.log(item.fileName);
                this.$message.warning(
                  "[" + item[0].fileName + "]已经申请下载,请耐心等待"
                );
              }
            }
          } else {
            this.$message.error(re.message);
          }
        });
      }
    },
    doCloseload() {
      this.centerDialogVisible = false;
      this.fileKey = "";
      this.fileName = "";
      this.totalNum = 0;
      this.errorNum = 0;
      this.successNum = 0;
      this.getData();
    },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less" scoped>
.searchboxfl {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start !important;
}
.faceStudentstatistics {
  .operationControl {
    display: flex;
    flex-direction: column !important;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .mabtm {
    padding-bottom: 0.675rem;
    border-bottom: 1px solid #f5f5f6;
  }
  .searchboxItem {
    display: flex;
    flex-direction: row;
    align-items: center;

    .el-cascader {
      height: 32px !important;
    }
  }
}
.searchBox {
  .el-icon-arrow-down:before {
    content: "\e6df" !important;
  }
}
.bh /deep/ .el-textarea__inner {
  height: 200px;
}
.exportdialog {
  .el-dialog__title {
    font-size: 16px;
  }
  .export-box {
    width: 100%;
    margin-bottom: 20px;
    h1 {
      padding: 0 5px 10px;
      display: flex;
      align-items: flex-end;
      font-size: 40px;
      border-bottom: 1px dashed #ccc;
      span {
        margin-left: 20px;
        font-size: 14px;
      }
    }
    > div {
      padding-left: 30px;
    }
    .el-button.is-disabled,
    .el-button.is-disabled:focus,
    .el-button.is-disabled:hover {
      color: #fff;
      background: #c3c3c3;
      border: none;
    }
  }
}
</style>
<style lang="less">
.exportdialog {
  .upload-workers {
    height: 30px;
    .el-upload {
      height: 30px !important;
      width: 97px;
      border: none !important;
      .el-button {
        padding: 5px 0;
      }
    }
  }
}
</style>
